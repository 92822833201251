<script setup lang="ts"></script>

<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.2 130.2"
  >
    <circle
      class="path circle animation-[dash_0.9s_ease-in-out] stroke-danger"
      fill="none"
      stroke-dasharray="1000"
      stroke-dashoffset="0"
      stroke-miterlimit="10"
      stroke-width="6"
      cx="65.1"
      cy="65.1"
      r="62.1"
    />
    <line
      class="path line animate-[dash_0.9s_0.35s_ease-in-out_forwards] stroke-danger"
      fill="none"
      stroke-dasharray="1000"
      stroke-dashoffset="-100"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="6"
      x1="34.4"
      y1="37.9"
      x2="95.8"
      y2="92.3"
    />
    <line
      class="path line animate-[dash_0.9s_0.35s_ease-in-out_forwards] stroke-danger"
      fill="none"
      stroke-dasharray="1000"
      stroke-dashoffset="-100"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="6"
      x1="95.8"
      y1="38"
      x2="34.4"
      y2="92.2"
    />
  </svg>
</template>

<style></style>
